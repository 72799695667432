import axios from "axios";
import React, { useState } from "react";
import { IFile } from "../InsightsManager";

interface IDeleteModalProps {
  file: IFile;
  setShowDeleteModal: (bool: boolean) => void;
  accessToken: string;
  onFileChanges: () => void;
}

enum ModalState {
  PENDING,
  SUCCESS,
  FAILURE,
  WAITING_FOR_USER,
}

const DeleteConfirmationModal: React.FC<IDeleteModalProps> = ({
  file,
  setShowDeleteModal,
  accessToken,
  onFileChanges,
}) => {
  const [modalState, setDeletionState] = useState<ModalState>(
    ModalState.WAITING_FOR_USER,
  );

  const handleCancelEvent = () => {
    setShowDeleteModal(false);
  };

  const handleSuccessfulCloseEvent = () => {
    setShowDeleteModal(false);
    onFileChanges();
  };

  const deleteFile = async () => {
    try {
      await axios.delete(`${process.env.GATSBY_API_URL}/api/files/delete`, {
        headers: {
          "northstar-authorization": `Bearer ${accessToken}`,
        },
        params: {
          id: file.metadata.id,
        },
      });

      setDeletionState(ModalState.SUCCESS);
    } catch (error) {
      setDeletionState(ModalState.FAILURE);
    }
  };

  const handleConfirmationEvent = () => {
    setDeletionState(ModalState.PENDING);
    deleteFile();
  };

  const renderButtons = () => {
    switch (modalState) {
      case ModalState.WAITING_FOR_USER:
        return (
          <>
            <div
              className={`mr-4 cursor-pointer hover:font-bold`}
              onClick={() => handleConfirmationEvent()}
            >
              Confirm
            </div>
            <div
              className={`cursor-pointer hover:font-bold`}
              onClick={() => handleCancelEvent()}
            >
              Cancel
            </div>
          </>
        );
      case ModalState.PENDING:
        return <div>Deleting...</div>;
      case ModalState.SUCCESS:
        return (
          <>
            <div className={`mr-4 text-blue`}>Success!</div>
            <div
              className={`cursor-pointer hover:font-bold`}
              onClick={() => handleSuccessfulCloseEvent()}
            >
              Close
            </div>
          </>
        );
      case ModalState.FAILURE:
        return (
          <>
            <div className={`mr-4 text-red-500`}>
              Failure! Please close and try again.
            </div>
            <div
              className={`cursor-pointer hover:font-bold`}
              onClick={() => handleCancelEvent()}
            >
              Close
            </div>
          </>
        );
    }
  };

  return (
    <div className="fixed inset-0 bg-white bg-opacity-50 h-screen w-screen top-[100px] flex justify-center items-center z-50">
      <div className="relative top-[-100px] p-5 border border-gray-light w-96 shadow-lg rounded-lg bg-white flex flex-col min-h-[150px]">
        <div>
          Confirm that you want to delete the file{" "}
          <strong>{file.metadata.name}</strong>.
        </div>
        <div className="grow"></div>
        <div className="flex w-full justify-end">{renderButtons()}</div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
