import React, { useEffect, useReducer, useRef, useState } from "react";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import axios from "axios";
import FileUpload from "../FileUpload";
import DataTable from "../Grid/dataTable";

export interface ICompany {
  name: string;
  domain: string;
  id: number;
}

export interface IFileMetadata {
  isPublic: boolean;
  id: string;
  name: string;
  description?: string;
  path: string;
  companies: ICompany[];
}

export interface IFile {
  metadata: IFileMetadata;
  companies: ICompany[];
}

const BAM_DOMAINS = ["bamelevate.com", "bamfunds.com"];

export default () => {
  const [allCompanies, setCompanies] = useState<ICompany[]>([]);
  const [files, setFiles] = useState<Map<string, IFile>>(new Map());

  const { accessToken, whoami } = useAuthContext();

  const { data } = useNorthstar("/api/northstar-users", accessToken, {
    method: "get",
    fields: ["name", "domain"],
    sort: ["name:asc"],
  });

  useEffect(() => {
    if (data?.data) {
      setCompanies(
        data.data.map(
          (company: {
            id: number;
            attributes: { name: string; domain: string };
          }) => {
            return {
              id: company.id,
              ...company.attributes,
            };
          },
        ),
      );
    }
  }, [JSON.stringify(data?.data || "")]);

  const getFilesForCompanies = async () => {
    const filesMap = new Map();

    for (const company of allCompanies) {
      if (
        BAM_DOMAINS.includes(company.domain) &&
        company.domain !== whoami?.company?.domain // since we cannot use the impersonation API to impersonate bamfunds or bamelevate users, lets skip over the bam domain that we are not currently logged in as
      )
        continue;

      const headers = {
        "northstar-authorization": `Bearer ${accessToken}`,
      };
      if (!BAM_DOMAINS.includes(company.domain)) {
        headers["northstar-impersonate"] = "impersonation@" + company.domain; // only add this header if current company in loop is not bam domain
      }

      const files = await axios.get(
        `${process.env.GATSBY_API_URL}/api/files?pagination[pageSize]=100`,
        {
          headers: headers,
        },
      );

      for (const file of files.data.data) {
        if (!filesMap.get(file.attributes.uuid)) {
          filesMap.set(file.attributes.uuid, {
            metadata: {
              id: file.id,
              uuid: file.attributes.uuid,
              name: file.attributes.name,
              description: file.attributes.description,
              isPublic: file.attributes.isPublic,
              users: [],
              file: file.attributes,
            },
            companies: [],
          });
        }
        filesMap.set(file.attributes.uuid, {
          metadata: filesMap.get(file.attributes.uuid).metadata,
          companies: [
            ...filesMap.get(file.attributes.uuid).companies,
            { name: company.name, domain: company.domain, id: company.id },
          ],
        });
      }
    }

    setFiles(filesMap);
  };

  useEffect(() => {
    getFilesForCompanies();
  }, [allCompanies]);

  const refreshFiles = () => {
    getFilesForCompanies();
  };

  return (
    <>
      <FileUpload onUploadComplete={() => refreshFiles()} />
      <DataTable
        files={files}
        allCompanies={allCompanies}
        onFileChanges={refreshFiles}
      />
    </>
  );
};
