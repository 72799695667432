import React, { useCallback, useState } from "react";

interface FileUploaderProps {
  onFileSelect: (file: File) => void;
  selectedFile: File;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onFileSelect,
  selectedFile,
}) => {
  const [dragActive, setDragActive] = useState(false);

  // Function to handle file selection via input
  const onFileInput = (event: React.DragEvent<HTMLDivElement>) => {
    onFileSelect(event.target.files[0]);

    // remove file from input field after file processing is complete
    event.target.value = "";
  };

  // Function to handle drag over event
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  // Function to handle drag leave
  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
  };

  // Function to handle file drop
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      onFileSelect(event.dataTransfer.files[0]);
    }
  };

  return (
    <div className="p-4 max-w-[1000px] min-w-[500px] min-h-[250px]">
      <div
        className={`w-full h-full border-2 border-dashed border-blue rounded-lg flex justify-center items-center ${
          dragActive ? "bg-off-white" : "bg-white"
        }`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <input
          type="file"
          onInput={onFileInput}
          className="hidden"
          id="fileInput"
        />
        <label
          htmlFor="fileInput"
          className="cursor-pointer text-center rounded-lg w-full h-full flex flex-col justify-center items-center"
        >
          <div>Drag and drop a file here or click to select a file.</div>
          <div className="mt-3 font-bold">
            {selectedFile && selectedFile.name && selectedFile.name}
          </div>
        </label>
      </div>
    </div>
  );
};

export default FileUploader;
