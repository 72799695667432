import axios from "axios";
import React, { useState } from "react";
import { ICompany, IFile } from "../InsightsManager";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Dropdown from "../Dropdown";

interface IEditWindowProps {
  file: IFile;
  setShowEditModal: (bool: boolean) => void;
  accessToken: string;
  allCompanies: ICompany[];
  onFileChanges: () => void;
}

enum ModalState {
  PENDING,
  SUCCESS,
  FAILURE,
  WAITING_FOR_USER,
}

const EditModal: React.FC<IEditWindowProps> = ({
  file,
  setShowEditModal,
  accessToken,
  allCompanies,
  onFileChanges,
}) => {
  const [modalState, setEditState] = useState<ModalState>(
    ModalState.WAITING_FOR_USER,
  );

  const [permissionedUsers, setPermissionedUsers] = useState<{}>(() => {
    const users = {};
    file.companies.forEach((c) => {
      users[c.id] = c.name;
    });
    return users;
  });

  const handleCancelEvent = () => {
    setShowEditModal(false);
  };

  const handleSuccessfulCloseEvent = () => {
    setShowEditModal(false);
    onFileChanges();
  };

  const updatePermissionedUsers = async () => {
    const res = await axios.put(
      `${process.env.GATSBY_API_URL}/api/files/update`,
      {
        id: file.metadata.id,
        users: Object.keys(permissionedUsers).map((id) => Number(id)),
      },
      {
        headers: {
          "northstar-authorization": `Bearer ${accessToken}`,
        },
      },
    );
  };

  const handleConfirmationEvent = async () => {
    try {
      setEditState(ModalState.PENDING);
      await updatePermissionedUsers();
      setEditState(ModalState.SUCCESS);
    } catch (err) {
      setEditState(ModalState.FAILURE);
    }
  };

  const renderButtons = () => {
    switch (modalState) {
      case ModalState.WAITING_FOR_USER:
        return (
          <>
            <div
              className={`mr-4 cursor-pointer hover:font-bold`}
              onClick={() => handleConfirmationEvent()}
            >
              Confirm
            </div>
            <div
              className={`cursor-pointer hover:font-bold`}
              onClick={() => handleCancelEvent()}
            >
              Cancel
            </div>
          </>
        );
      case ModalState.PENDING:
        return <div>Processing...</div>;
      case ModalState.SUCCESS:
        return (
          <>
            <div className={`mr-4 text-blue`}>Success!</div>
            <div
              className={`cursor-pointer hover:font-bold`}
              onClick={() => handleSuccessfulCloseEvent()}
            >
              Close
            </div>
          </>
        );
      case ModalState.FAILURE:
        return (
          <>
            <div className={`mr-4 text-red-500`}>
              Failure! Please close and try again.
            </div>
            <div
              className={`cursor-pointer hover:font-bold`}
              onClick={() => handleCancelEvent()}
            >
              Close
            </div>
          </>
        );
    }
  };

  const handleRemoval = (id: string) => {
    const usersToDisplay = { ...permissionedUsers };
    delete usersToDisplay[id];

    setPermissionedUsers(usersToDisplay);
  };

  const handleAddition = (id: number, user: string) => {
    const usersToDisplay = { ...permissionedUsers };
    usersToDisplay[id] = user;

    setPermissionedUsers(usersToDisplay);
  };

  const renderCompanies = () => {
    return (
      <div className=" mb-4">
        {Object.entries(permissionedUsers).map(([id, name]) => {
          return (
            <div className="flex items-center" key={name}>
              <XMarkIcon
                className={`text-red-500 h-4 mr-1 ${
                  modalState === ModalState.WAITING_FOR_USER && "cursor-pointer"
                }`}
                onClick={() => {
                  modalState === ModalState.WAITING_FOR_USER &&
                    handleRemoval(id);
                }}
              />
              <div>{name} </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateDropdownOptions = () => {
    const dropdownCompanies = [];

    allCompanies.forEach((company) => {
      if (!permissionedUsers[company.id.toString()])
        dropdownCompanies.push({
          text: company.name,
          onClick: () => {
            handleAddition(company.id, company.name);
          },
        });
    });

    return dropdownCompanies;
  };

  return (
    <div className="fixed inset-0 bg-white bg-opacity-50 h-screen w-screen top-[100px] flex justify-center items-center z-50">
      <div className="relative top-[-100px] p-5 border border-gray-light w-96 shadow-lg rounded-lg bg-white flex flex-col min-h-[150px]">
        <div className="mb-4">
          Add or remove users for <strong>{file.metadata.name}</strong>.
        </div>
        {renderCompanies()}
        <div className="mb-4">
          <Dropdown
            disabled={modalState === ModalState.WAITING_FOR_USER ? false : true}
            dropdownText={`Add user`}
            dropDownOptions={generateDropdownOptions()}
            dropdownOptionsClassname="min-w-[144px]"
          />
        </div>
        <div className="flex w-full justify-end">{renderButtons()}</div>
      </div>
    </div>
  );
};

export default EditModal;
