import React, { useEffect, useState } from "react";
import ConfirmationWindow from "./ConfirmationWindow";

import { XMarkIcon } from "@heroicons/react/24/solid";

import { useAuthContext } from "../../contexts/authContext";
import FileUploader from "../FileUploader";
import Dropdown, { DropDownStyle } from "../Dropdown";
import useNorthstar from "../../hooks/northstarHook";

const RE_FILE_NAME_TARGET = /^[a-zA-Z0-9-_. ]+$/;
enum documentType {
  INSIGHT = "Insight",
  RESOURCE = "Resource",
}

export default ({ onUploadComplete }) => {
  const { accessToken } = useAuthContext();

  const [file, setFile] = useState<File | null>(null);

  const [displayName, setDisplayName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [companies, setCompanies] = useState<{ id: number; name: string }[]>(
    [],
  );
  const [tags, setTags] = useState<{ id: number; name: string }[]>([]);

  const [selectedTagsNew, setSelectedTagsNew] = useState<{
    [id: number]: string;
  }>({});
  const [selectedCompaniesNew, setSelectedCompaniesNew] = useState<{
    [id: number]: string;
  }>({});

  const [docType, setDocType] = useState<documentType>(documentType.INSIGHT);
  const [isDownloadable, setIsDownloadable] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showRegexFailure, setShowRegexFailure] = useState<boolean>(false);

  const [showConfirmationWindow, setShowConfirmationWindow] =
    useState<boolean>(false);

  const { data } = useNorthstar("/api/northstar-users", accessToken, {
    method: "get",
    fields: ["name"],
    sort: ["name:asc"],
  });

  useEffect(() => {
    if (data?.data) {
      setCompanies(
        data.data.map(
          (company: { id: string; attributes: { name: string } }) => {
            const { id } = company;
            const { name } = company.attributes;
            return { id, name };
          },
        ),
      );
    }
  }, [JSON.stringify(data?.data || "")]);

  const { data: tagNames } = useNorthstar("/api/tags", accessToken, {
    method: "get",
    fields: ["name"],
    sort: ["name:asc"],
  });

  useEffect(() => {
    if (tagNames?.data) {
      setTags(
        tagNames.data.map(
          (tag: { id: string; attributes: { name: string } }) => {
            const { id } = tag;
            const { name } = tag.attributes;
            return { id, name };
          },
        ),
      );
    }
  }, [JSON.stringify(tagNames?.data || "")]);

  const isUploadButtonDisabled = () => {
    if (
      !file ||
      (Object.keys(selectedCompaniesNew).length > 0 && isPublic) ||
      (Object.keys(selectedCompaniesNew).length === 0 && !isPublic)
    )
      return true;
    return false;
  };

  const handleSuccessfulUpload = () => {
    setShowConfirmationWindow(false);
    setShowSuccess(true);

    setShowSuccess(true);
    setFile(null);
    setSelectedCompaniesNew({});
    setSelectedTagsNew({});
    setDisplayName("");
    setDescription("");
    setDocType(documentType.INSIGHT);
    setIsDownloadable(false);
    setIsPublic(false);

    setTimeout(() => {
      setShowSuccess(false);
    }, 4000);
    onUploadComplete();
  };

  const handleFileNameRegexFailure = () => {
    setShowRegexFailure(true);
    setTimeout(() => {
      setShowRegexFailure(false);
    }, 8000);
  };

  const handleUploadButtonClick = async () => {
    if (RE_FILE_NAME_TARGET.test(file.name) === false) {
      handleFileNameRegexFailure();
      return;
    }

    setShowConfirmationWindow(true);
  };

  const showMakePublicError = () => {
    if (Object.keys(selectedCompaniesNew).length > 0 && isPublic) return true;
    return false;
  };

  const updateSelectedTags = (
    tag: { id: number; name: string },
    add: boolean,
  ) => {
    const newTags = { ...selectedTagsNew };

    if (add) newTags[tag.id] = tag.name;
    else delete newTags[tag.id];

    setSelectedTagsNew(newTags);
  };

  const updateSelectedCompanies = (
    tag: { id: number; name: string },
    add: boolean,
  ) => {
    const newCompanies = { ...selectedCompaniesNew };

    if (add) newCompanies[tag.id] = tag.name;
    else delete newCompanies[tag.id];

    setSelectedCompaniesNew(newCompanies);
  };

  return (
    <>
      <div className="mt-12">
        <div className="text-xl mb-4 font-semibold">
          Upload file for company
        </div>
        <div className="rounded-md bg-white w-full flex flex-wrap min-h-[380px]">
          <div className="flex flex-col">
            <div className="p-4">
              <div>
                Upload a file and make it visible to a specific company under{" "}
                <strong>Insights</strong> or <strong>Resources</strong>.
                <br></br>
                File names may only contain alphanumeric characters, hyphens,
                underscores, periods, or spaces.
              </div>
            </div>
            <FileUploader
              onFileSelect={(file: File) => {
                setFile(file);
              }}
              selectedFile={file}
            />
          </div>
          <div className="flex flex-col h-auto p-4">
            <div>
              <div className="text-bold text-lg mb-1">
                Company<span className="text-red-500">*</span>
              </div>
              <Dropdown
                dropdownText={`Select one or more companies`}
                dropDownOptions={companies.map((company) => {
                  return {
                    text: company.name,
                    onClick: () => {
                      updateSelectedCompanies(company, true);
                    },
                  };
                })}
                style={DropDownStyle.WHITE_INPUT}
                dropdownOptionsClassname="w-[450px]"
              />
            </div>
            <div className="mt-4">
              <div className="text-bold text-lg mb-1">Display Name</div>
              <input
                type="text"
                className="w-[450px] text-gray-900 placeholder:text-gray-400 border rounded-sm p-1"
                placeholder="Set a display name for the file (optional)"
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
                value={displayName}
              />
            </div>
            <div className="mt-4">
              <div className="text-bold text-lg mb-1">Description</div>
              <input
                type="text"
                className="w-[450px] text-gray-900 placeholder:text-gray-400 border rounded-sm p-1"
                placeholder="Set a description for the file (optional)"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                value={description}
              />
            </div>
            <div className="mt-4">
              <div className="text-bold text-lg mb-1">Tags</div>
              <Dropdown
                dropdownText={`Select tags`}
                dropDownOptions={tags.map((tag) => {
                  return {
                    text: tag.name,
                    onClick: () => {
                      updateSelectedTags(tag, true);
                    },
                  };
                })}
                style={DropDownStyle.WHITE_INPUT}
                dropdownOptionsClassname="w-[450px]"
              />
            </div>
            <div className="mt-4">
              <div className="text-bold text-lg mb-1">
                Document Type<span className="text-red-500">*</span>
              </div>
              <Dropdown
                dropdownText={docType || `Select type`}
                dropDownOptions={["Insight", "Resource"].map((type) => {
                  return {
                    text: type,
                    onClick: () => {
                      setDocType(type);
                    },
                  };
                })}
                style={DropDownStyle.WHITE_INPUT}
                dropdownOptionsClassname="w-[450px]"
              />
            </div>
            <div className="mt-4">
              <div className="text-bold text-lg mb-1">
                Allow Download<span className="text-red-500">*</span>
              </div>
              <Dropdown
                dropdownText={isDownloadable ? "Yes" : "No"}
                dropDownOptions={["Yes", "No"].map((option) => {
                  return {
                    text: option,
                    onClick: () => {
                      setIsDownloadable(option === "Yes" ? true : false);
                    },
                  };
                })}
                style={DropDownStyle.WHITE_INPUT}
                dropdownOptionsClassname="w-[450px]"
              />
            </div>
            <div className="mt-4">
              <div className="text-bold text-lg mb-1">
                Make Public<span className="text-red-500">*</span>
              </div>
              <Dropdown
                dropdownText={isPublic ? "Yes" : "No"}
                dropDownOptions={["Yes", "No"].map((option) => {
                  return {
                    text: option,
                    onClick: () => {
                      setIsPublic(option === "Yes" ? true : false);
                    },
                  };
                })}
                style={DropDownStyle.WHITE_INPUT}
                dropdownOptionsClassname="w-[450px]"
              />
            </div>
            <div className="grow"></div>
            <div className="flex">
              <div>
                {showSuccess && (
                  <div className="mt-3 text-blue font-semibold">
                    File has been successfully uploaded!
                  </div>
                )}
                {showRegexFailure && (
                  <div className="mt-3 text-red-500 font-semibold">
                    Check characters in file name and try again!
                  </div>
                )}
                {showMakePublicError() && (
                  <div className="mt-3 text-red-500 font-semibold text-sm">
                    File cannot be public and have permissioned companies.
                    <br></br>Remove selected companies and try again.
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end">
              <button
                disabled={isUploadButtonDisabled()}
                onClick={() => {
                  handleUploadButtonClick();
                }}
                className={`mt-3 w-auto px-2 py-1 border border-transparent text-white rounded-full text-xs tracking-widest self-end ${
                  isUploadButtonDisabled()
                    ? "bg-gray-light"
                    : "bg-blue hover:bg-orange cursor-pointer"
                }`}
              >
                Upload
              </button>
            </div>
          </div>
          <div className="p-4">
            <div className="font-semibold mb-2">Selected companies:</div>
            {Object.entries(selectedCompaniesNew).map((company) => {
              return (
                <div className="flex items-center" key={company[1]}>
                  <XMarkIcon
                    className="h-4 cursor-pointer mr-1 text-red-500"
                    onClick={() =>
                      updateSelectedCompanies(
                        { id: parseInt(company[0]), name: company[1] },
                        false,
                      )
                    }
                  />
                  <div>{company[1]} </div>
                </div>
              );
            })}
          </div>
          <div className="p-4">
            <div className="font-semibold mb-2">Selected tags:</div>
            {Object.entries(selectedTagsNew).map((tag) => {
              return (
                <div className="flex items-center" key={tag[1]}>
                  <XMarkIcon
                    className="h-4 cursor-pointer mr-1 text-red-500"
                    onClick={() =>
                      updateSelectedTags(
                        { id: parseInt(tag[0]), name: tag[1] },
                        false,
                      )
                    }
                  />
                  <div>{tag[1]} </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {showConfirmationWindow && (
        <ConfirmationWindow
          file={file}
          selectedCompanies={selectedCompaniesNew}
          selectedTags={selectedTagsNew}
          handleSuccessfulUpload={handleSuccessfulUpload}
          setShowConfirmationWindow={setShowConfirmationWindow}
          displayName={displayName}
          description={description}
          accessToken={accessToken}
          docType={docType}
          isDownloadable={isDownloadable}
          isPublic={isPublic}
        />
      )}
    </>
  );
};
