import React, { useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import { ICompany, IFile } from "../InsightsManager";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import EditModal from "./EditModal";

interface IActionButtonProps {
  file: IFile;
  allCompanies: ICompany[];
  onFileChanges: () => void;
}

const ActionButtons: React.FC<IActionButtonProps> = ({
  file,
  allCompanies,
  onFileChanges,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { accessToken } = useAuthContext();
  return (
    <>
      <div className="relative inline-block text-left ml-2 min-w-[120px]">
        <div className="flex items-center text-sm">
          <div
            className={`border-l border-y border-l-gray border-y-gray py-1 px-3 rounded-l-lg ${
              file.metadata.isPublic
                ? "bg-gray-300"
                : "cursor-pointer text-blue"
            }`}
            onClick={() => {
              !file.metadata.isPublic && setShowEditModal(true);
            }}
          >
            Edit
          </div>
          <div
            className="py-1 px-3 bg-blue border-r border-y border-r-blue border-y-blue text-white rounded-r-lg cursor-pointer"
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            Delete
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmationModal
          file={file}
          setShowDeleteModal={setShowDeleteModal}
          accessToken={accessToken}
          onFileChanges={onFileChanges}
        />
      )}
      {showEditModal && (
        <EditModal
          file={file}
          setShowEditModal={setShowEditModal}
          accessToken={accessToken}
          allCompanies={allCompanies}
          onFileChanges={onFileChanges}
        />
      )}
    </>
  );
};

export default ActionButtons;
