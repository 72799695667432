import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import React, { useState } from "react";

interface IConfirmationWindowProps {
  file: File;
  selectedCompanies: { [id: number]: string };
  selectedTags: { [id: number]: string };
  handleSuccessfulUpload: () => void;
  setShowConfirmationWindow: (bool: boolean) => void;
  displayName: string;
  description: string;
  accessToken: string;
  docType: string;
  isDownloadable: boolean;
  isPublic: boolean;
}

const ConfirmationWindow: React.FC<IConfirmationWindowProps> = ({
  file,
  selectedCompanies,
  selectedTags,
  handleSuccessfulUpload,
  setShowConfirmationWindow,
  displayName,
  description,
  accessToken,
  docType,
  isDownloadable,
  isPublic,
}) => {
  const [isUploadPending, setIsUploadPending] = useState<boolean>(false);

  const [allUploadsSucceeded, setAllUploadsSucceeded] =
    useState<boolean>(false);

  const uploadFile = async () => {
    setIsUploadPending(true);

    const res = await axios.post(
      `${process.env.GATSBY_API_URL}/api/files`,
      {
        displayName: displayName || file.name,
        name: file.name,
        description,
        ext: file.name.split(".").pop(),
        isDownloadable,
        isPublic,
        documentType: docType,
        size: file.size,
        tags: Object.keys(selectedTags),
        permissionedUsers: Object.keys(selectedCompanies),
      },
      {
        headers: {
          "northstar-authorization": `Bearer ${accessToken}`,
        },
      },
    );

    if (res.status === 200) {
      const presignedUrl = await axios.post(
        `${process.env.GATSBY_API_URL}/api/files/${res.data.uuid}/open`,
        {
          name: file.name,
        },
        {
          headers: {
            "northstar-authorization": `Bearer ${accessToken}`,
          },
        },
      );

      const headers = {
        "Content-Type": file.type,
      };
      await axios.put(presignedUrl.data, file, {
        headers,
      });
    }

    setIsUploadPending(false);
    setAllUploadsSucceeded(true);
  };

  const confirmationText = () => {
    return isPublic
      ? `Confirm that you want to upload the file ${file.name} for all companies.`
      : `Confirm that you want to upload the file ${file.name} for the following companies:`;
  };

  return (
    <div className="fixed inset-0 bg-white bg-opacity-50 h-screen w-screen top-[100px] flex justify-center items-center z-50">
      <div className="relative top-[-100px] p-5 border border-gray-light w-96 shadow-lg rounded-lg bg-white flex flex-col">
        <XMarkIcon
          className="h-4 text-gray w-min self-end cursor-pointer"
          onClick={() =>
            allUploadsSucceeded
              ? handleSuccessfulUpload()
              : setShowConfirmationWindow(false)
          }
        />
        <div className="mt-3 text-center">{confirmationText()}</div>
        <div className="min-h-[80px] mt-2">
          {Object.entries(selectedCompanies).map((company) => {
            return (
              <div className="flex items-center" key={company[1]}>
                <div className="mr-2">{company[1]}</div>
              </div>
            );
          })}
        </div>
        {allUploadsSucceeded ? (
          <button
            onClick={() => {
              handleSuccessfulUpload();
            }}
            className={`w-auto px-2 py-1 border border-transparent text-white rounded-full text-xs tracking-widest self-end bg-blue hover:bg-orange cursor-pointer`}
          >
            Success! Close window
          </button>
        ) : (
          <button
            disabled={isUploadPending}
            onClick={() => {
              !isUploadPending && uploadFile();
            }}
            className={`w-auto px-2 py-1 border border-transparent text-white rounded-full text-xs tracking-widest self-end bg-blue ${
              !isUploadPending && "cursor-pointer hover:bg-orange"
            }`}
          >
            {isUploadPending ? "Uploading" : "Confirm and upload"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ConfirmationWindow;
