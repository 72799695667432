import { navigate } from "gatsby";
import { isAxiosError } from "axios";
import React, { useState, useEffect } from "react";

import { useAuthContext } from "../../contexts/authContext";
import useNorthstar, { request } from "../../hooks/northstarHook";
import NorthstarPageLayout from "../../components/NorthstarPageLayout";

import { BAM_DOMAINS } from "../../constants";
import Modal from "../../components/Modal";
import Dropdown, { DropDownStyle } from "../../components/Dropdown";
import InsightsManager from "../../components/InsightsManager";

const RE_IMPERSONATION_TARGET =
  /^([a-zA-Z0-9-+_~.]+)@([a-zA-Z0-9-+_~]+)(\.[a-zA-Z0-9-+_~]+)+/;

const AdminToolsPage: React.FC = () => {
  const { accessToken, impersonation, clearIdentity, setImpersonation } =
    useAuthContext();

  const [subject, setSubject] = useState<string | null>(null);
  const [impersonationInProgress, setImpersonationInProgress] =
    useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string | null>(
    null,
  );
  const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false);

  const { data: whoami, error: whoAmIError } = useNorthstar(
    "/api/auth/whoami",
    accessToken,
    { method: "get" },
  );

  const { data: companies, error: companiesError } = useNorthstar(
    "/api/northstar-users",
    accessToken,
    {
      method: "get",
      params: {
        fields: ["domain"],
        sort: ["name"],
        "pagination[page]": 1,
        "pagination[pageSize]": 100,
      },
    },
  );

  useEffect(() => {
    if (!impersonation) return;

    navigate("/");
  }, [impersonation]);

  const isImpersonateButtonDisabled = (): boolean => {
    return subject == null || impersonationInProgress;
  };

  const handleImpersonate = (subject: string | null) => {
    if (!subject || !RE_IMPERSONATION_TARGET.test(subject)) return;

    setImpersonationInProgress(true);

    return request("/api/auth/whoami", accessToken, {
      method: "get",
      headers: {
        "northstar-impersonate": subject,
      },
    })
      .then((_) => {
        setImpersonation(subject);
      })
      .catch((err) => {
        if (!isAxiosError(err)) throw err;

        setSubject(null);

        const code = err.response.status;
        const text = err.response.data?.error?.message ?? err.response.data;

        setErrorModalMessage(`Impersonation failed with HTTP ${code}: ${text}`);
        setErrorModalVisible(true);
      })
      .finally(() => {
        setImpersonationInProgress(false);
      });
  };

  useEffect(() => {
    let ignore = true;

    if (
      (companiesError?.status === 401 || whoAmIError?.status === 401) &&
      !ignore
    )
      clearIdentity();

    return () => {
      ignore = false;
    };
  }, [whoAmIError?.status, companiesError?.status]);

  useEffect(() => {
    if (!whoami) return;

    const domain = whoami.username.split("@")[1];

    if (!BAM_DOMAINS.has(domain)) navigate("/");
  }, [whoami?.username]);

  return (
    <NorthstarPageLayout title={"Admin"} showAdminBar={true}>
      <Modal visible={errorModalVisible} setIsVisible={setErrorModalVisible}>
        {errorModalMessage}
      </Modal>
      <div>
        <div className="text-xl mb-4 font-semibold">Impersonate user</div>
        <div className="rounded-md bg-white w-full grid grid-cols-5 items-center">
          <div className="col-span-3 m-5">
            <p>
              View Northstar as an external user for testing and support
              purposes by entering a valid user's e-mail and clicking{" "}
              <strong>Impersonate</strong>. Note: all impersonation activity is
              logged for auditing purposes.
            </p>
          </div>
          <Dropdown
            dropdownText={subject || "Select a company"}
            dropDownOptions={(companies?.data ?? [])
              .filter(
                ({
                  attributes: { domain },
                }: {
                  attributes: { domain: string };
                }) => !BAM_DOMAINS.has(domain),
              )
              .map(
                ({
                  attributes: { domain },
                }: {
                  attributes: { domain: string };
                }) => ({
                  text: domain,
                  onClick: () => setSubject(`demo@${domain}`),
                }),
              )}
            style={DropDownStyle.WHITE_INPUT}
            width="w-5/6"
            dropdownOptionsClassname="w-[300px]"
          />
          <button
            type="submit"
            disabled={isImpersonateButtonDisabled()}
            onClick={(event) => {
              //event.preventDefault();
              handleImpersonate(subject);
            }}
            className={`w-fit items-center px-2 py-1 border border-transparent text-white text-center font-medium rounded-full ${
              isImpersonateButtonDisabled()
                ? "bg-gray-light"
                : "bg-blue hover:bg-orange cursor-pointer"
            } text-xs tracking-widest`}
          >
            Impersonate
          </button>
        </div>
        <InsightsManager />
      </div>
    </NorthstarPageLayout>
  );
};

export default AdminToolsPage;
