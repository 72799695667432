import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { IFile, ICompany } from "../InsightsManager";
import ActionButtons from "./ActionButtons";

const FilterComponent = ({ filterText, onFilter }) => {
  return (
    <div className="flex justify-between mb-4 items-center">
      <input
        id="search"
        type="text"
        placeholder="Filter by name, description, or users"
        value={filterText}
        onChange={onFilter}
        className="p-2 border rounded border-[#e0e0e0] focus:outline-none w-80"
      />
    </div>
  );
};

interface IDataTableProps {
  files: Map<string, IFile>;
  allCompanies: ICompany[];
  onFileChanges: () => void;
}

const dataTable: React.FC<IDataTableProps> = ({
  files,
  allCompanies,
  onFileChanges,
}) => {
  const [filterText, setFilterText] = useState("");

  const generateDataRows = (files: IFile[]) => {
    const createRows = (files) => {
      const rows = [];
      for (const file of files) {
        rows.push({
          id: file.metadata.uuid,
          name: file.metadata.name,
          description: file.metadata.description,
          users: file.companies,
          file: file,
        });
      }
      return rows;
    };

    const filteredFiles = Array.from(files.values()).filter((file) => {
      return (
        file.metadata.name.toLowerCase().includes(filterText.toLowerCase()) ||
        file.companies
          .map((user) => user.name)
          .join(",")
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        file.metadata.description
          ?.toLowerCase()
          .includes(filterText.toLowerCase())
      );
    });

    return createRows(filteredFiles);
  };

  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const renderUserList = (users: ICompany[], file: IFile) => {
    return (
      <div className="flex items-center justify-between w-full">
        <div className="text-wrap max-w-[400px]">
          {file.metadata.isPublic
            ? "All users"
            : users
                .map((company) => {
                  return company.name;
                })
                .sort((a, b) => (a < b ? -1 : 1))
                .join(", ")}
        </div>
        <ActionButtons
          file={file}
          allCompanies={[...allCompanies]}
          onFileChanges={onFileChanges}
        />
      </div>
    );
  };

  const generateColumns = () => {
    return [
      {
        name: "ID",
        cell: (row) => row.id,
      },
      {
        name: "Name",
        sortable: true,
        cell: (row) => row.name,
        selector: (row) => row.name.toLowerCase(),
      },
      {
        name: "Description",
        sortable: true,
        cell: (row) => row.description,
        selector: (row) => row.description,
        sortFunction: (a, b) => {
          if (
            (a.description?.toLowerCase() || "") <
            (b.description?.toLowerCase() || "")
          )
            return -1;
          return 1;
        },
      },
      {
        name: "Users",
        cell: (row) => {
          return renderUserList(row.users, row.file);
        },
      },
    ];
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#f9fafb",
      },
    },
    cells: {
      style: {
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    subHeader: {
      style: {
        justifyContent: "flex-start",
        padding: "0px",
      },
    },
  };

  return (
    <div className="mt-12">
      <div className="text-xl mb-4 font-semibold rounded-sm">
        Manage Insights
      </div>
      <div className="bg-white rounded-md min-h-[500px] w-full p-4">
        {files.size > 0 ? (
          <DataTable
            customStyles={customStyles}
            columns={generateColumns()}
            data={generateDataRows(Array.from(files.values()))}
            subHeader
            subHeaderComponent={subHeaderComponent}
            pagination
            defaultSortFieldId={2}
          />
        ) : (
          "Loading insights..."
        )}
      </div>
    </div>
  );
};

export default dataTable;
